import {
  FreelancerBreakpoints,
  FreelancerMaxBreakpoint,
} from '@freelancer/ui/breakpoints';
import { isDefined } from '@freelancer/utils';
import type {
  GenerateImageSizesOptions,
  ImageResourceSizesInput,
} from './picture-utils.types';

/**
 * Helper function to generate the `sizes` attribute for the picture element.
 *
 * The `sizes attribute Used to specify expected intrinsic image sizes depending on specified breakpoints
 * Useful for responsive images and image optimization.
 *
 *
 * @see {@link https://html.spec.whatwg.org/multipage/images.html#source-size-value | HTML spec of the sizes attribute}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#sizes | MDN - img sizes attribute documentation}
 * @see {@link https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images | MDN - Responsive Images}
 *
 * @example
 * // We only want to use 768px for all breakpoints
 * {defaultValue: '768px'} = "768px"
 *
 * // For mobile breakpoint, select an image that would take the whole viewport width
 * { mobile: '100vw', defaultValue: '768px' } = "(max-width: 767.9px) 100vw, 768px"
 *
 *
 * // The image takes the whole viewport width for mobile, 2 images are displayed in tablet, and 4 for the rest of the breakpoints
 * { mobile: '100vw', tablet: '384px', defaultValue: '194px' } = "(max-width: 767.9px) 100vw, (max-width: 959.9px) 384px, 194px"
 */
export function generateImageSizes({
  mobile,
  minMobile,
  tablet,
  minTablet,
  desktopSmall,
  minDesktopSmall,
  desktopLarge,
  minDesktopLarge,
  desktopXLarge,
  minDesktopXLarge,
  desktopXXLarge,
  minDesktopXXLarge,
  desktopXXXLarge,
  minDesktopXXXLarge,
  defaultSize,
}: GenerateImageSizesOptions): string {
  const sizes: readonly {
    size?: ImageResourceSizesInput;
    mediaQuery: string;
  }[] = [
    {
      size: mobile,
      mediaQuery: FreelancerMaxBreakpoint.TABLET,
    },
    {
      size: minMobile,
      mediaQuery: FreelancerBreakpoints.MOBILE,
    },
    {
      size: tablet,
      mediaQuery: FreelancerMaxBreakpoint.DESKTOP_SMALL,
    },
    {
      size: minTablet,
      mediaQuery: FreelancerBreakpoints.TABLET,
    },
    {
      size: desktopSmall,
      mediaQuery: FreelancerMaxBreakpoint.DESKTOP_LARGE,
    },
    {
      size: minDesktopSmall,
      mediaQuery: FreelancerBreakpoints.DESKTOP_SMALL,
    },
    {
      size: desktopLarge,
      mediaQuery: FreelancerMaxBreakpoint.DESKTOP_XLARGE,
    },
    {
      size: minDesktopLarge,
      mediaQuery: FreelancerBreakpoints.DESKTOP_LARGE,
    },
    {
      size: desktopXLarge,
      mediaQuery: FreelancerMaxBreakpoint.DESKTOP_XXLARGE,
    },
    {
      size: minDesktopXLarge,
      mediaQuery: FreelancerBreakpoints.DESKTOP_XLARGE,
    },
    {
      size: desktopXXLarge,
      mediaQuery: FreelancerMaxBreakpoint.DESKTOP_XXXLARGE,
    },
    {
      size: minDesktopXXLarge,
      mediaQuery: FreelancerBreakpoints.DESKTOP_XXLARGE,
    },
    {
      size: desktopXXXLarge,
      mediaQuery: FreelancerMaxBreakpoint.DESKTOP_XXXXLARGE,
    },
    {
      size: minDesktopXXXLarge,
      mediaQuery: FreelancerBreakpoints.DESKTOP_XXXLARGE,
    },
    {
      size: defaultSize,
      mediaQuery: '',
    },
  ];

  return sizes
    .filter(({ size }) => isDefined(size))
    .map(({ mediaQuery, size }) =>
      mediaQuery ? `${mediaQuery} ${size}` : size,
    )
    .join(', ');
}
